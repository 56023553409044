import { replaceOriginInURL } from "~~/lib/utils/replaceOriginInURL";
export const PageDecorator = locale => function (utag, jsonld, seo, path, breadcrumb, banner) {
  let supplimentaryMetadata = arguments.length > 6 && arguments[6] !== undefined ? arguments[6] : [];
  let defaultTripUrl = arguments.length > 7 ? arguments[7] : undefined;
  const domain = "https://www.intrepidtravel.com";
  const domainWithPath = domain + path;
  return {
    title: `${seo.meta_title} | Intrepid Travel ${locale.code.toUpperCase()}`,
    jsonld,
    metadata: [...supplimentaryMetadata, ..._mapContentstackSEOToMetaData(seo, domainWithPath, domain)],
    utag,
    breadcrumb,
    banner: banner,
    defaultTripUrl
  };
};
export const _mapContentstackSEOToMetaData = (seoEntry, path, domain) => {
  var _seoEntry$open_graph;
  const metadata = [{
    property: "og:url",
    content: path
  }, {
    property: "og:type",
    content: ((_seoEntry$open_graph = seoEntry.open_graph) === null || _seoEntry$open_graph === void 0 ? void 0 : _seoEntry$open_graph.og_type) || "website"
  }];
  const description = _getDescription(seoEntry);
  const ogDescription = _getOgDescription(seoEntry);
  const ogTitle = _getOgTitle(seoEntry);
  const ogImage = _getOgImage(seoEntry, domain);
  const noIndex = _getNoIndex(seoEntry);
  if (description) {
    metadata.push(description);
  }
  if (ogDescription) {
    metadata.push(ogDescription);
  }
  if (ogTitle) {
    metadata.push(ogTitle);
  }
  if (ogImage) {
    metadata.push(ogImage);
  }
  if (noIndex) {
    metadata.push(noIndex);
  }
  return metadata;
};
export const _getDescription = seoEntry => {
  if (seoEntry.description) {
    return {
      hid: "description",
      name: "description",
      content: seoEntry.description
    };
  }
};
export const _getOgImage = (seoEntry, domain) => {
  var _seoEntry$open_graph2, _seoEntry$open_graph3;
  if ((_seoEntry$open_graph2 = seoEntry.open_graph) !== null && _seoEntry$open_graph2 !== void 0 && (_seoEntry$open_graph3 = _seoEntry$open_graph2.sharing_image) !== null && _seoEntry$open_graph3 !== void 0 && _seoEntry$open_graph3.url) {
    var _seoEntry$open_graph4, _seoEntry$open_graph5;
    return {
      property: "og:image",
      content: replaceOriginInURL((_seoEntry$open_graph4 = seoEntry.open_graph) === null || _seoEntry$open_graph4 === void 0 ? void 0 : _seoEntry$open_graph4.sharing_image.url, domain) || ((_seoEntry$open_graph5 = seoEntry.open_graph) === null || _seoEntry$open_graph5 === void 0 ? void 0 : _seoEntry$open_graph5.sharing_image.url)
    };
  }
  if (useRuntimeConfig().public.defaultMetaImage) {
    return {
      property: "og:image",
      content: useRuntimeConfig().public.defaultMetaImage
    };
  }
};
export const _getOgDescription = seoEntry => {
  var _seoEntry$open_graph6;
  if ((_seoEntry$open_graph6 = seoEntry.open_graph) !== null && _seoEntry$open_graph6 !== void 0 && _seoEntry$open_graph6.og_description) {
    var _seoEntry$open_graph7;
    return {
      property: "og:description",
      content: (_seoEntry$open_graph7 = seoEntry.open_graph) === null || _seoEntry$open_graph7 === void 0 ? void 0 : _seoEntry$open_graph7.og_description
    };
  }
  if (seoEntry.description) {
    return {
      property: "og:description",
      content: seoEntry.description
    };
  }
};
export const _getOgTitle = seoEntry => {
  var _seoEntry$open_graph8;
  if ((_seoEntry$open_graph8 = seoEntry.open_graph) !== null && _seoEntry$open_graph8 !== void 0 && _seoEntry$open_graph8.og_title) {
    var _seoEntry$open_graph9;
    return {
      property: "og:title",
      content: (_seoEntry$open_graph9 = seoEntry.open_graph) === null || _seoEntry$open_graph9 === void 0 ? void 0 : _seoEntry$open_graph9.og_title
    };
  }
  if (seoEntry.meta_title) {
    return {
      property: "og:title",
      content: `${seoEntry.meta_title} | Intrepid Travel`
    };
  }
};
export const _getNoIndex = seoEntry => {
  if (seoEntry.no_index) {
    return {
      name: "robots",
      content: "noindex"
    };
  }
};